.header {
  width: 100%;
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  transition: background-color 0.6s cubic-bezier(0.64, 0.04, 0.26, 0.87);

  @media @desktop {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

/* Change background header */
.header-bg {
  background-color: @elements-basic;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
