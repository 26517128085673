.card {
  height: 100%;
  padding: 1.8em 1.4em;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background-color: @background-auxiliary;
}

.card__top {
  display: flex;
  align-items: center;

  &--info {
    height: 70px;
    align-items: center;
    margin-bottom: 0.8em;

    @media @desktop {
      margin-bottom: 1.2em;
    }
  }
}

.card__title {
  margin: 0;
  font-weight: 600;
  font-size: 1.2rem;
  color: @text-additional;

  @media @tablet {
    font-size: 1.4rem;
  }

  &--info {
    flex: 1 200px;
  }
}

.card__text {
  margin: 0;
  color: @text-additional;
}

.card__image {
  width: 3.6rem;
  height: 3.6rem;
  margin-right: 0.8em;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: @text-additional;
  border-radius: 50%;
  .transition-general;
}

.card__icon {
  width: 36px;
  height: 36px;
  fill: @elements-basic;
  .transition-general;

  &--contact,
  &--travel,
  &--restaurant {
    width: 30px;
    height: 30px;
    margin-top: 4px;
  }

  &--travel {
    margin-top: 2px;
  }

  &--restaurant {
    margin-top: 0;
  }

  &--poster {
    width: 41px;
    height: 41px;
    margin-top: 2px;
  }

  &--cinema {
    width: 32px;
    height: 32px;
  }

  &--concert {
    width: 44px;
    height: 44px;
  }
}
