.restaurants {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.restaurants__container {
  padding-top: 5em;
  padding-bottom: 4em;
}

.restaurants__tabs-list {
  width: 100%;
  margin-bottom: 2em;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  @media @tablet {
    justify-content: flex-start;
  }
}

.restaurants__tabs-item {
  &--moscow {
    margin-right: 0.5em;
  }

  &--st-petersburg {
    margin-left: 0.5em;
  }

  @media @desktop {
    &--moscow {
      margin-right: 1.6em;
    }

    &--st-petersburg {
      margin-left: 1.6em;
    }
  }
}

.restaurants__tabs-button {
  width: 9rem;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: @title;
  cursor: pointer;
  padding: 1em 0;
  border: none;
  outline: none;
  border: 1px solid @title;
  background-color: @text-additional;
  border-radius: 14px;
  font-size: 0.8rem;
  cursor: pointer;
  .transition-general;
  transition-delay: 0.1s;

  &:hover {
    background-color: @title;
    color: @text-additional;
    box-shadow: 0px 10px 30px @auxiliary-opacity;
  }

  &--active {
    background-color: @title;
    color: @text-additional;
    box-shadow: 0px 10px 30px @auxiliary-opacity;
  }

  @media @tablet {
    width: 14rem;
    font-size: inherit;
  }
}

.restaurants__list {
  width: 100%;
  max-width: 100%;
  display: flex;
  column-gap: 3em;
  flex-wrap: wrap;
}

.restaurants__item {
  align-self: normal;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
  border-radius: 20px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  flex: 1 300px;
  animation: fadeIn 0.6s;
  .transition-general;

  &--st-petersburg {
    display: none;
  }
}

.hovered:hover {
  cursor: pointer;
  transform: scale(1.02);
}

.restaurants__title {
  margin: 0;
  margin-bottom: 0.4em;
  font-weight: 600;
  font-size: 1.2rem;
  color: @text-form;

  @media @tablet {
    font-size: 1.4rem;
  }
}

.restaurants__item-footer {
  display: flex;
  flex-direction: column;
  padding: 0 1em 1em;
}

.restaurants__image {
  margin-bottom: 0.7em;
  border-radius: 20px 20px 0 0;
  object-fit: cover;
}

.restaurant__description {
  margin-bottom: 0;
  margin-top: auto;
  font-size: 1rem;
}

.restaurant__button {
  width: 58px;
  height: 58px;
  position: absolute;
  top: 16px;
  right: 16px;

  background-color: @text-additional-opacity-extra;
  border: none;
  border-radius: 50%;
  background-image: url("../img/icon-heart.svg");
  background-repeat: no-repeat;
  background-size: 30px 30px;
  background-position: center;
  .transition-general;

  &:hover {
    background-color: @text-additional;
  }
}

.liked {
  background-image: url("../img/icon-heart-filled.svg");
}
