.beauty {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.beauty__container {
  padding-top: 5em;
  padding-bottom: 4em;
}

.beauty__list {
  max-width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 20px;

  @media @desktop {
    grid-template-columns: repeat(auto-fill, minmax(460px, 1fr));
  }
}

.beauty__item {
  position: relative;
  .transition-general;
  cursor: pointer;

  &:hover {
    transform: scale(1.02);
  }

  &:nth-child(1) {
    grid-row: span 2;
  }

  &:nth-child(5) {
    grid-row: span 2;
  }

  &:nth-child(7) {
    grid-row: span 2;
  }

  @media @desktop {
    margin-bottom: 0;
  }
}

.beauty__title {
  position: absolute;
  top: 12px;
  left: 20px;
}
