.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media @tablet {
    width: 70%;
    margin: 0 auto;
  }

  @media @desktop {
    width: 50%;
    margin: 0 auto;
  }
}

.form__field {
  width: 100%;
  margin-bottom: 2em;
  position: relative;
  display: flex;
  flex-direction: column;
  font: inherit;

  &--textarea {
    margin-bottom: 1.1em;
  }

  &--email {
    margin-bottom: 4.1em;
  }
}

.form__field.unfilled .form__input {
  border-bottom: 1px solid @error;
}

.form__field.unfilled .form__textarea {
  border: 1px solid @error;
}

.form__label {
  margin-inline: 22px;
  position: absolute;
  top: 0;
  background-color: transparent;
  color: @text-form;
  .transition-general;

  &--textarea {
    visibility: hidden;
  }
}

.form__input {
  width: 100%;
  position: relative;
  color: @text-form;
  border-style: hidden;
  appearance: none;
  border-radius: 0;
  border-bottom: 1px solid @title;
  padding: 0.85em 0.75em;
  line-height: 1.6rem;
  background-color: transparent;
  caret-color: @elements-basic;
  .transition-general;

  &::placeholder {
    opacity: 0;
  }

  &:focus,
  &:active {
    outline: none;
    border-bottom: 1px solid @elements-basic;
    background-color: transparent;
  }
}

.form__input:-webkit-autofill,
.form__input:-webkit-autofill:hover,
.form__input:-webkit-autofill:focus,
.form__input:-webkit-autofill:active {
  box-shadow: inset 0 0 0 1000px transparent;
  -webkit-text-fill-color: rgba(57, 57, 57, 0.8) !important;
  transition: background-color 50000s;
  -webkit-text-fill-color: rgba(57, 57, 57, 0.8) !important;
  font-family: @font-family-basic !important;
}

.form__input:focus ~ .form__label,
.form__input:not(:placeholder-shown) ~ .form__label {
  transform: translate3d(0, -24px, 0);
  background-color: transparent;
  color: @elements-basic;
}

.form__textarea {
  height: 12rem;
  padding: 22px;
  border-radius: 14px;
  background-color: @text-additional;
  border: 1px solid @title;
  color: @text-form;
  caret-color: @elements-basic;
  resize: vertical;
  .transition-general;

  &::placeholder {
    color: @text-form;
  }

  &:focus::placeholder {
    opacity: 0;
  }

  &:focus,
  &:active {
    outline: none;
    border: 1px solid @elements-basic;
  }

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 0;
    background: @text-additional;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: @text-additional;
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }

  @media @tablet {
    height: 15rem;
    padding: 15px;
  }

  @media @desktop {
    height: 17rem;
  }
}

.form__warning {
  margin: 0;
  margin-top: 0.2em;
  color: @error;
  opacity: 0;
  .transition-general;
}

.form__button {
  width: 12rem;
  appearance: none;
  border: none;
  outline: none;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: @text-additional;
  padding: 1em 0;
  font-family: @font-family-basic;
  font-weight: 400;
  font-size: inherit;
  background-color: @title;
  border-radius: 14px;
  cursor: pointer;
  .transition-general;
  transition-delay: 0.1s;

  &:hover {
    background-color: @text-additional;
    color: @title;
    box-shadow: 0px 10px 30px @auxiliary-opacity;
  }

  &--search {
    background-color: @elements-basic;

    &:hover {
      background-color: @text-additional;
      color: @elements-basic;
      box-shadow: 0px 10px 30px @background-auxiliary-opacity;
    }
  }
}

.form__button.disabled {
  opacity: 0.2;
  cursor: none;
}

.form__warnings {
  margin-bottom: 1em;
  text-align: center;
}

.form__warnings-text {
  margin: 0;
}

.error {
  color: @error;
  display: none;
}

.success {
  color: @title;
  display: none;
}
