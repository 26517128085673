.privacy {
  padding-bottom: 2em;
  background-color: #f9f9f9;
}

.privacy__header {
  width: 100%;
  margin-bottom: 2em;
  padding: 3em 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(180deg, #e5e5e5 0%, #ffffff 100%);
}

.privacy__title {
  margin-bottom: 3em;
}

.privacy__content {
  padding: 1.4em;
  background-color: white;
  box-shadow: 2px 2px 5px 1px rgb(211, 211, 211);
}

.privacy__text {
  margin-bottom: 2em;
}

.privacy__content ul {
  padding-left: 1em;
  list-style-type: disc;

  @media @tablet {
    padding-left: 2em;
  }
}

.privacy__content p {
  margin-top: 0;
}

.privacy__content h2 {
  margin-top: 1.5em;
}

.privacy__content h3,
.privacy__content h4 {
  margin-bottom: 0.4em;
}

.privacy__content h4 {
  margin-top: 0.4em;
}

.privacy__content a {
  color: #353535;
  cursor: pointer;
  .transition-general;
}

.privacy__content a:hover {
  color: @title;
}
