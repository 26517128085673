.tickets__form-field {
  width: 100%;
  margin-bottom: 1em;

  &:last-child {
    margin-bottom: 0;
  }

  &--passengers,
  &--date {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &--poster {
    margin-bottom: 2em;
  }

  &--date-modal {
    margin-bottom: 2.2em;
  }
}

.tickets__date-label {
  margin-bottom: 0.7em;
  text-transform: uppercase;
  color: @title;
}
