.contact {
  width: 100%;
  height: 100svh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact__list {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;

  @media @tablet {
    width: fit-content;
    max-width: fit-content;
    margin: 0 auto;
  }
}

.contact__item {
  margin-bottom: 1em;

  &:last-child {
    margin-bottom: 0;
  }
}

.contact__link {
  width: 100%;
  display: flex;
  align-items: center;
  color: @text-form;
  .transition-general;

  &:hover {
    color: @title;
  }

  &:hover .contact__image {
    background-color: @text-additional;
  }

  &:hover .contact__icon {
    fill: @title;
  }
}

.contact__image {
  background-color: @title;
  margin-right: 2em;
}

.contact__icon {
  width: 24px;
  height: 24px;
  fill: @text-additional;
  .transition-general;

  &--email {
    width: 30px;
    height: 30px;
  }
}
