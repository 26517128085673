html {
  box-sizing: border-box;
  overflow-x: hidden;
  scroll-behavior: smooth;
  font-size: 100%;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

body {
  width: 100%;
  min-width: 360px;
  margin: 0 auto;
  padding: 0;
  font-family: @font-family-basic;
  font-size: clamp(1rem, 2vw + 1rem, 1.2rem);
  line-height: 1.55;
  color: @text-form;
  letter-spacing: normal;
}

img {
  display: block;
  width: 100%;
  height: auto;
  object-fit: contain;
}

ul {
  list-style-type: none;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
}

a {
  text-decoration: none;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.container {
  min-width: 360px;
  max-width: 1340px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;

  @media @tablet {
    padding-left: 50px;
    padding-right: 50px;
  }

  @media @desktop {
    width: 100%;
  }
}

.disable-scroll {
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
}
