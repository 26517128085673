@font-face {
  font-family: "OpenSans";
  src: local("OpenSans Regular"), local("OpenSans-Regular"),
    url("../fonts/opensans.woff2") format("woff2"),
    url("../fonts/opensans.woff") format("woff");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "OpenSans";
  src: local("OpenSans Bold"), local("OpenSans-Bold"),
    url("../fonts/opensansbold.woff2") format("woff2"),
    url("../fonts/opensansbold.woff") format("woff");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}
