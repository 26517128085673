.section {
  margin-bottom: 4em;

  @media @desktop {
    margin-bottom: 5em;
  }

  &--services,
  &--info {
    margin-bottom: 3em;

    @media @desktop {
      margin-bottom: 2em;
    }
  }
}

.section__title {
  width: 100%;
  margin: 0;
  margin-bottom: 1em;
  font-family: @font-family-basic;
  font-weight: 600;
  font-size: 2rem;
  text-transform: uppercase;
  color: @title;

  &--contact {
    text-align: center;
    color: @elements-basic;
  }

  @media @tablet {
    margin-bottom: 1.4em;
    font-size: 2.2rem;
  }

  @media @desktop {
    margin-bottom: 2em;
  }

  &--privacy {
    font-size: 1.4rem;
    text-align: center;
    color: @text-form;

    @media @tablet {
      font-size: 2rem;
    }
  }
}
