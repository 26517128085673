@font-family-basic: "OpenSans", "Arial", sans-serif;
@text-additional: #fefefe;
@text-additional-opacity: rgba(254, 254, 254, 0.7);
@text-additional-opacity-extra: rgba(254, 254, 254, 0.4);
@text-form: rgba(57, 57, 57, 0.8);

@image-fallback: #0f1424;

@background-auxiliary: #5799fc;
@auxiliary-opacity: #a6c8fb;
@background-auxiliary-opacity: #ffe7c2;

@elements-basic: #ffb13a;
@elements-basic-opacity: rgba(255, 177, 58, 0.5);
@title: #428bf9;
@border: @title;
@error: #f86f03;
@slide-transition: cubic-bezier(0.4, 0, 0, 1);
@mobile-width-only: 667px;
@tablet-width: 768px;
@desktop-width: 1150px;
@mobile-only: ~"(max-width: @{mobile-width-only})";
@tablet: ~"(min-width: @{tablet-width})";
@desktop: ~"(min-width: @{desktop-width})";
@retina-dpi: 144dpi;
@retina-dppx: 1.5dppx;
@retina-1: ~"(min-resolution: @{retina-dpi})";
@retina-2: ~"(min-resolution: @{retina-dppx})";

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0.2;
  }
  40% {
    opacity: 0.4;
  }
  60% {
    opacity: 0.6;
  }
  80% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
