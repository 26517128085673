.nav {
  width: 100%;
  max-width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media @tablet {
    height: 110px;
  }

  @media @desktop {
    height: fit-content;
  }
}

.nav__logo {
  margin-right: 2.4em;
}

.nav__logo-img {
  width: 38px;
  border-radius: 10px;

  @media @tablet {
    width: 50px;
  }
}

.nav__menu {
  display: flex;

  @media @desktop {
    width: 80%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
}

.nav__list {
  width: 100%;
  height: 100%;
  padding: 80px 30px;
  position: fixed;
  top: 0;
  left: -100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: @elements-basic-opacity;
  backdrop-filter: blur(32px);
  -webkit-backdrop-filter: blur(32px);
  .transition-general;
  z-index: 3;
  overflow-y: scroll;

  &.show-menu {
    left: 0;
  }

  @media @tablet {
    padding-right: 60px;
    padding-left: 60px;
  }

  @media @desktop {
    flex-basis: 80%;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 0;
    padding-left: 0;
    position: relative;
    left: 0;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: transparent;
    border: none;
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
    align-items: center;
    justify-content: flex-end;
    overflow-y: hidden;
  }
}

.nav__item {
  margin-bottom: 1.8em;

  &:last-child {
    margin-bottom: 0;
  }

  @media @tablet {
    margin-bottom: 3em;
  }

  @media @desktop {
    margin-bottom: 0;
    margin-left: 3em;

    &:first-child {
      margin-left: 0;
    }
  }
}

.nav__link {
  text-transform: uppercase;
  letter-spacing: 0.5px;
  .transition-general;
  cursor: pointer;
  color: @text-additional-opacity;

  &:active,
  &:hover,
  &:focus {
    color: @title;
  }

  &--active {
    color: @text-additional;
    pointer-events: none;
    font-weight: 600;
  }
}

.nav__lang-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-basis: 150px;
  margin-left: auto;
  margin-right: auto;

  @media @desktop {
    flex-basis: 120px;
    margin-right: 0;
  }
}

.nav__lang-item {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: transparent;

  &:active,
  &:hover,
  &:focus {
    background-color: @text-additional-opacity;
  }

  &--active {
    background-color: @title;
    pointer-events: none;

    .nav__lang-link {
      color: @text-additional;
    }
  }

  @media @tablet {
    width: 52px;
    height: 52px;
  }
}

.nav__lang-link {
  padding: 0.6em;
  font-size: 1rem;
  color: @title;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  .transition-general;
  cursor: pointer;

  &:active,
  &:hover,
  &:focus {
    color: @auxiliary-opacity;
  }

  @media @tablet {
    font-size: clamp(1rem, 2vw + 1rem, 1.2rem);
  }
}

.nav__toggle {
  width: 26px;
  height: 28px;
  margin-left: 1.6em;
  position: relative;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  z-index: 4;

  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 0;
    height: 3px;
    width: 100%;
    background-color: @text-additional;
    .transition-general;
    border-radius: 2px;
  }

  &::before {
    top: 25%;
  }
  &::after {
    bottom: 25%;
  }

  @media @tablet {
    width: 32px;
    height: 34px;

    &::before,
    &::after {
      height: 4px;
    }
  }

  @media @desktop {
    display: none;
  }
}

.nav__toggle.active::before,
.nav__toggle.active::after {
  top: 45%;
  transform: translate(45%, 45%);
}

.nav__toggle.active::before {
  transform: rotate(45deg);
}

.nav__toggle.active::after {
  transform: rotate(-45deg);
}
