.home,
.info {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-image: url("../img/hero-bg@1x.png");
  background-repeat: no-repeat;
  background-position: bottom 150px right -170px;
  background-size: cover;

  @media @retina-1, @retina-2 {
    background-image: url("../img/hero-bg@2x.png");
  }

  @media @tablet {
    background-position: bottom 150px right -130px;
  }

  @media @desktop {
    background-position: bottom 80px right -240px;
  }
}

.home {
  height: 100svh;
}

.home-image__container {
  width: 9em;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 40%;
  right: 7px;

  @media @tablet {
    right: 10%;
  }

  @media @desktop {
    width: 280px;
    top: 50%;
    transform: translateY(-50%);
    right: 16%;
  }
}

.home__image {
  max-width: 100%;
  object-fit: cover;
}

.home__title {
  width: 56%;
  margin: 0;
  font-family: @font-family-basic;
  font-weight: 600;
  font-size: 2.2rem;
  color: @background-auxiliary;
  text-transform: uppercase;

  @media @tablet {
    font-size: 2.8rem;
  }

  @media @desktop {
    font-size: 5rem;
  }
}
