.footer {
  padding: 3em;
  background-color: @elements-basic;
  color: white;
}

.footer__container {
  display: flex;
  flex-basis: 100%;
  flex-wrap: wrap;
  max-width: 1340px;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;

  @media @desktop {
    padding-left: 50px;
    padding-right: 50px;
    flex-direction: row;
    justify-content: space-between;
  }
}

.footer__name {
  margin-bottom: 1.2em;
  color: @title;
  font-size: 1.4rem;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  cursor: none;

  @media @desktop {
    width: 30%;
    margin-bottom: 0;
    text-align: left;
  }
}

.footer__contact-list {
  width: 100%;
  margin-bottom: 1.6em;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media @desktop {
    width: 65%;
    margin-bottom: 0;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.footer__contact-item {
  margin-bottom: 0.8em;

  &:last-child {
    margin-bottom: 0;
  }

  @media @desktop {
    width: 47.5%;
    margin-bottom: 0;
  }
}

.footer__contact-link {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  .transition-general;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    color: @title;
  }

  &:hover .footer__icon,
  &:focus .footer__icon,
  &:active .footer__icon {
    fill: @title;
  }

  @media @desktop {
    &--email {
      justify-content: flex-end;
    }
  }
}

.footer__icon {
  width: 24px;
  height: 24px;
  margin-right: 1em;
  fill: @text-additional;
  cursor: pointer;
}

.footer__privacy {
  color: white;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
  .transition-general;

  &:hover,
  &:active {
    color: @title;
  }

  @media @desktop {
    margin: 0 auto;
    padding-top: 1.2em;
  }
}
