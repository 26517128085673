.poster__container {
  padding-top: 5em;
  padding-bottom: 4em;
}

.poster__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.poster__list {
  margin-bottom: 2em;
}

.poster__form-field {
  margin-bottom: 2em;
}
