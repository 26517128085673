.list {
  width: 100%;
  max-width: 100%;
  display: flex;
  column-gap: 3em;
  flex-wrap: wrap;
}

.list__item {
  margin-bottom: 1em;
  border-radius: 20px;
  .transition-general;

  &:hover {
    box-shadow: 0px 10px 30px @auxiliary-opacity;
  }

  &:hover .card__image {
    background-color: @elements-basic;
  }

  &:hover .card__icon {
    fill: @text-additional;
  }

  @media @desktop {
    margin-bottom: 3em;
  }

  &--services {
    flex: 1 320px;
  }

  &--info {
    flex: 1 320px;
    align-self: normal;
    pointer-events: none;
  }

  &--poster {
    pointer-events: none;
  }
}
