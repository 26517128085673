.info {
  height: 100svh;
}

.info__content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.info__title {
  margin-bottom: 0.4em;
}

.info__subtitle {
  width: 60%;
  margin: 0;
  color: @title;
  font-weight: 400;
  font-size: 1rem;

  @media @tablet {
    font-size: 1.2rem;
  }

  @media @desktop {
    max-width: 53%;
    font-size: 1.4rem;
  }
}
