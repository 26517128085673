.modal__container {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: @text-additional-opacity;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  opacity: 1;
  transition: opacity 250ms ease-in-out;
  z-index: 4;
  pointer-events: all;
  animation: fadeIn 0.3s ease-in;
}

.modal-shake {
  animation: shake 0.3s ease-in-out;
}

.modal__content {
  width: 340px;
  max-height: calc(100vh - 40px);
  overflow-y: auto;
  padding: 5em 1.4em 1.8em;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  background-color: @text-additional;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  position: relative;

  &--success {
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  }

  @media @tablet {
    width: 410px;
  }
}

.modal__text {
  margin: 0;
  margin-bottom: 2em;
  text-align: center;

  &--success {
    margin-bottom: 2.3em;
    font-size: 1.4rem;
    color: @elements-basic;
  }
}

.modal__form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal__form-field {
  width: 100%;
  position: relative;
  margin-bottom: 2em;
}

.modal__form-field > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  font-size: inherit;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.3s ease-in-out, transform 0.4s ease-in-out;
  color: @elements-basic;
  text-transform: uppercase;
}

.modal__form-field > .modal__input:focus ~ label,
.modal__form-field > .modal__input:not(:placeholder-shown) ~ label {
  top: -20px;
  transform: scale(0.85) translate(0.15rem, -0.5rem);
}

.modal__input::placeholder {
  font-size: 0;
}

.modal__input {
  width: 100%;
  display: block;
  appearance: none;
  border-style: hidden;
  border: none;
  outline: none;
  border-radius: 0;
  height: 2em;
  color: @text-form;
  border-bottom: 1px solid @title;

  &:focus,
  &:active {
    outline: none;
    background-color: transparent;
  }
}

.modal__input:-webkit-autofill,
.modal__input:-webkit-autofill:hover,
.modal__input:-webkit-autofill:focus,
.modal__input:-webkit-autofill:active {
  box-shadow: inset 0 0 0 1000px transparent;
  -webkit-text-fill-color: rgba(57, 57, 57, 0.8) !important;
  transition: background-color 50000s;
  -webkit-text-fill-color: rgba(57, 57, 57, 0.8) !important;
  font-family: @font-family-basic !important;
}

.modal__button {
  width: 12rem;
  appearance: none;
  border: none;
  outline: none;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: @text-additional;
  padding: 1em 0;
  font-family: @font-family-basic;
  font-weight: 400;
  font-size: inherit;
  background-color: @elements-basic;
  border-radius: 14px;
  cursor: pointer;
  .transition-general;
  transition-delay: 0.1s;

  &:hover {
    background-color: @text-additional;
    color: @elements-basic;
    box-shadow: 0px 10px 30px @background-auxiliary-opacity;
  }
}

.modal__close {
  width: 58px;
  height: 58px;
  position: absolute;
  top: 1em;
  right: 1em;
  background-color: @title;
  border: none;
  border-radius: 50%;
  background-image: url("../img/icon-close.svg");
  background-repeat: no-repeat;
  background-size: 30px 30px;
  background-position: center;
  cursor: pointer;
  .transition-general;

  &:hover {
    background-color: @auxiliary-opacity;
  }
}
